<template>
  <div class="mt-4">
    <Card>
      <h4 class="text-black font-bold">
        A few more steps to activate your Zilla life account
      </h4>
      <p class="mt-2 text-grey text-sm">
        Here are the list of things you need to do to get your account active
        and funded.
      </p>
      <Hr class="mt-6" />
      <!-- phone -->
      <div
        class="flex items-center justify-between py-4"
        :class="[
          qualification.PHONE_NUMBER_CHECK.eligibilityStatus !== 'ELIGIBLE'
            ? 'cursor-pointer'
            : 'cursor-not-allowed',
        ]"
      >
        <div>
          <p class="text-lg text-black">Verify Phone number</p>
          <p
            class="mt-1 text-xs text-brandRed"
            v-if="
              qualification.PHONE_NUMBER_CHECK.eligibilityStatus !== 'ELIGIBLE'
            "
          >
            {{ qualification.PHONE_NUMBER_CHECK.reason }}
          </p>
        </div>

        <svg
          v-if="
            qualification.PHONE_NUMBER_CHECK.eligibilityStatus === 'ELIGIBLE'
          "
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
            fill="#10DF88"
          />
        </svg>
      </div>
      <Hr />
      <!-- address -->
      <div
        class="flex items-center justify-between py-4"
        :class="[
          qualification.CONTACT_INFORMATION_CHECK.eligibilityStatus !==
          'ELIGIBLE'
            ? 'cursor-pointer'
            : 'cursor-not-allowed',
        ]"
      >
        <div>
          <p class="text-lg text-black">Address details</p>
          <p
            class="mt-1 text-xs text-brandRed"
            v-if="
              qualification.CONTACT_INFORMATION_CHECK.eligibilityStatus !==
              'ELIGIBLE'
            "
          >
            {{ qualification.CONTACT_INFORMATION_CHECK.reason }}
          </p>
        </div>
        <svg
          v-if="
            qualification.CONTACT_INFORMATION_CHECK.eligibilityStatus ===
            'ELIGIBLE'
          "
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
            fill="#10DF88"
          />
        </svg>
      </div>
      <Hr />
      <!-- employment -->
      <div
        class="flex items-center justify-between py-4"
        :class="[
          qualification.EMPLOYMENT_STATUS_CHECK.eligibilityStatus !== 'ELIGIBLE'
            ? 'cursor-pointer'
            : 'cursor-not-allowed',
        ]"
      >
        <div>
          <p class="text-lg text-black">Employment details</p>
          <p
            class="mt-1 text-xs text-brandRed"
            v-if="
              qualification.EMPLOYMENT_STATUS_CHECK.eligibilityStatus !==
              'ELIGIBLE'
            "
          >
            {{ qualification.EMPLOYMENT_STATUS_CHECK.reason }}
          </p>
        </div>

        <svg
          v-if="
            qualification.EMPLOYMENT_STATUS_CHECK.eligibilityStatus ===
            'ELIGIBLE'
          "
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
            fill="#10DF88"
          />
        </svg>
      </div>
      <Hr />
      <!-- selfie -->
      <div
        class="flex items-center justify-between py-4"
        :class="[
          qualification.SELFIE_CHECK.eligibilityStatus !== 'ELIGIBLE'
            ? 'cursor-pointer'
            : 'cursor-not-allowed',
        ]"
      >
        <div>
          <p class="text-lg text-black">Selfie upload</p>
          <p
            class="mt-1 text-xs text-brandRed"
            v-if="qualification.SELFIE_CHECK.eligibilityStatus !== 'ELIGIBLE'"
          >
            {{ qualification.SELFIE_CHECK.reason }}
          </p>
        </div>

        <svg
          v-if="qualification.SELFIE_CHECK.eligibilityStatus === 'ELIGIBLE'"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
            fill="#10DF88"
          />
        </svg>
      </div>
      <Hr />
      <!-- card -->
      <div
        class="flex items-center justify-between py-4"
        :class="[
          qualification.DEBIT_CARD_CHECK.eligibilityStatus !== 'ELIGIBLE'
            ? 'cursor-pointer'
            : 'cursor-not-allowed',
        ]"
      >
        <div>
          <p class="text-lg text-black">Card setup</p>
          <p
            class="mt-1 text-xs text-brandRed"
            v-if="
              qualification.DEBIT_CARD_CHECK.eligibilityStatus !== 'ELIGIBLE'
            "
          >
            {{ qualification.DEBIT_CARD_CHECK.reason }}
          </p>
        </div>
        <svg
          v-if="qualification.DEBIT_CARD_CHECK.eligibilityStatus === 'ELIGIBLE'"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
            fill="#10DF88"
          />
        </svg>
      </div>
      <Hr />
      <!-- bank -->
      <div
        class="flex items-center justify-between py-4"
        :class="[
          salary.eligibilityStatus !== 'ELIGIBLE'
            ? 'cursor-pointer'
            : 'cursor-not-allowed',
        ]"
      >
        <div class="w-4/5">
          <p class="text-lg text-black">Bank account setup</p>
          <p
            class="mt-1 text-xs text-brandRed"
            v-if="salary.eligibilityStatus !== 'ELIGIBLE'"
          >
            {{ salary.reason }}
          </p>
        </div>
        <svg
          v-if="salary.eligibilityStatus === 'ELIGIBLE'"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
            fill="#10DF88"
          />
        </svg>
      </div>
    </Card>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import Hr from "@/UI/Hr";
  export default {
    components: {
      Hr,
    },
    props: {
      qualification: {
        type: Object,
        default: () => {},
        required: true,
      },
      salary: {
        type: Object,
        default: () => {},
        required: true,
      },
    },

    methods: {
      ...mapActions("dashboard", ["selectActivateStep", "handleShowActivate"]),
      showTab(tab) {
        if (
          this.qualification.PHONE_NUMBER_CHECK.eligibilityStatus !== "ELIGIBLE"
        ) {
          this.selectActivateStep(tab);
          this.handleShowActivate(true);
        }
      },
      handlePhone() {
        if (
          this.qualification?.PHONE_NUMBER_CHECK?.eligibilityStatus !==
          "ELIGIBLE"
        ) {
          this.selectActivateStep(1);
          this.handleShowActivate(true);
        }
      },
      handleAddress() {
        if (
          this.qualification?.CONTACT_INFORMATION_CHECK?.eligibilityStatus !==
          "ELIGIBLE"
        ) {
          this.selectActivateStep(2);
          this.handleShowActivate(true);
        }
      },
      handleEmployment() {
        if (
          this.qualification?.EMPLOYMENT_STATUS_CHECK?.eligibilityStatus !==
          "ELIGIBLE"
        ) {
          this.selectActivateStep(3);
          this.handleShowActivate(true);
        }
      },
      handleSelfie() {
        if (
          this.qualification?.SELFIE_CHECK?.eligibilityStatus !== "ELIGIBLE"
        ) {
          this.selectActivateStep(4);
          this.handleShowActivate(true);
        }
      },
      handleCard() {
        if (
          this.qualification.DEBIT_CARD_CHECK.eligibilityStatus !== "ELIGIBLE"
        ) {
          this.selectActivateStep(5);
          this.handleShowActivate(true);
        }
      },
      handleBank() {
        if (this.salary?.eligibilityStatus !== "ELIGIBLE") {
          this.selectActivateStep(6);
          this.handleShowActivate(true);
        }
      },
    },
  };
</script>
<style scoped>
  .checked {
    background: #f3f4fd;
    border: 0.5px solid rgba(126, 118, 148, 0.26);
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  .eligibile-icon {
    height: 20px;
    width: 20px;
  }
</style>
