var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "Card",
        [
          _c("h4", { staticClass: "text-black font-bold" }, [
            _vm._v(" A few more steps to activate your Zilla life account "),
          ]),
          _c("p", { staticClass: "mt-2 text-grey text-sm" }, [
            _vm._v(
              " Here are the list of things you need to do to get your account active and funded. "
            ),
          ]),
          _c("Hr", { staticClass: "mt-6" }),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between py-4",
              class: [
                _vm.qualification.PHONE_NUMBER_CHECK.eligibilityStatus !==
                "ELIGIBLE"
                  ? "cursor-pointer"
                  : "cursor-not-allowed",
              ],
            },
            [
              _c("div", [
                _c("p", { staticClass: "text-lg text-black" }, [
                  _vm._v("Verify Phone number"),
                ]),
                _vm.qualification.PHONE_NUMBER_CHECK.eligibilityStatus !==
                "ELIGIBLE"
                  ? _c("p", { staticClass: "mt-1 text-xs text-brandRed" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.qualification.PHONE_NUMBER_CHECK.reason) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.qualification.PHONE_NUMBER_CHECK.eligibilityStatus ===
              "ELIGIBLE"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("Hr"),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between py-4",
              class: [
                _vm.qualification.CONTACT_INFORMATION_CHECK
                  .eligibilityStatus !== "ELIGIBLE"
                  ? "cursor-pointer"
                  : "cursor-not-allowed",
              ],
            },
            [
              _c("div", [
                _c("p", { staticClass: "text-lg text-black" }, [
                  _vm._v("Address details"),
                ]),
                _vm.qualification.CONTACT_INFORMATION_CHECK
                  .eligibilityStatus !== "ELIGIBLE"
                  ? _c("p", { staticClass: "mt-1 text-xs text-brandRed" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.qualification.CONTACT_INFORMATION_CHECK.reason
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.qualification.CONTACT_INFORMATION_CHECK.eligibilityStatus ===
              "ELIGIBLE"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("Hr"),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between py-4",
              class: [
                _vm.qualification.EMPLOYMENT_STATUS_CHECK.eligibilityStatus !==
                "ELIGIBLE"
                  ? "cursor-pointer"
                  : "cursor-not-allowed",
              ],
            },
            [
              _c("div", [
                _c("p", { staticClass: "text-lg text-black" }, [
                  _vm._v("Employment details"),
                ]),
                _vm.qualification.EMPLOYMENT_STATUS_CHECK.eligibilityStatus !==
                "ELIGIBLE"
                  ? _c("p", { staticClass: "mt-1 text-xs text-brandRed" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.qualification.EMPLOYMENT_STATUS_CHECK.reason
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.qualification.EMPLOYMENT_STATUS_CHECK.eligibilityStatus ===
              "ELIGIBLE"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("Hr"),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between py-4",
              class: [
                _vm.qualification.SELFIE_CHECK.eligibilityStatus !== "ELIGIBLE"
                  ? "cursor-pointer"
                  : "cursor-not-allowed",
              ],
            },
            [
              _c("div", [
                _c("p", { staticClass: "text-lg text-black" }, [
                  _vm._v("Selfie upload"),
                ]),
                _vm.qualification.SELFIE_CHECK.eligibilityStatus !== "ELIGIBLE"
                  ? _c("p", { staticClass: "mt-1 text-xs text-brandRed" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.qualification.SELFIE_CHECK.reason) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.qualification.SELFIE_CHECK.eligibilityStatus === "ELIGIBLE"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("Hr"),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between py-4",
              class: [
                _vm.qualification.DEBIT_CARD_CHECK.eligibilityStatus !==
                "ELIGIBLE"
                  ? "cursor-pointer"
                  : "cursor-not-allowed",
              ],
            },
            [
              _c("div", [
                _c("p", { staticClass: "text-lg text-black" }, [
                  _vm._v("Card setup"),
                ]),
                _vm.qualification.DEBIT_CARD_CHECK.eligibilityStatus !==
                "ELIGIBLE"
                  ? _c("p", { staticClass: "mt-1 text-xs text-brandRed" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.qualification.DEBIT_CARD_CHECK.reason) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.qualification.DEBIT_CARD_CHECK.eligibilityStatus ===
              "ELIGIBLE"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("Hr"),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between py-4",
              class: [
                _vm.salary.eligibilityStatus !== "ELIGIBLE"
                  ? "cursor-pointer"
                  : "cursor-not-allowed",
              ],
            },
            [
              _c("div", { staticClass: "w-4/5" }, [
                _c("p", { staticClass: "text-lg text-black" }, [
                  _vm._v("Bank account setup"),
                ]),
                _vm.salary.eligibilityStatus !== "ELIGIBLE"
                  ? _c("p", { staticClass: "mt-1 text-xs text-brandRed" }, [
                      _vm._v(" " + _vm._s(_vm.salary.reason) + " "),
                    ])
                  : _vm._e(),
              ]),
              _vm.salary.eligibilityStatus === "ELIGIBLE"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }